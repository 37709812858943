'use client';

import { Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Flex flexDirection={'column'} textAlign={'center'}>
      <Text>
        Ocorreu algum erro! Tente retornar para a página anterior e realizar a
        ação novamente.
      </Text>
    </Flex>
  );
}
